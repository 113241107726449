import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/phorkit/phorkit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { ArrowLeftIcon } from 'icons/ArrowLeftIcon';
import { ArrowRightIcon } from 'icons/ArrowRightIcon';
import { SearchIcon } from 'icons/SearchIcon';
import { SpinnerIcon } from 'icons/SpinnerIcon';
import { EmailIcon } from 'icons/internal/EmailIcon';
import { Button } from 'components/Button';
import { Flex } from 'components/Flex';
import { Looper } from 'components/Looper';
import { Rhythm } from 'components/Rhythm';
import { PageTitle } from 'docs/helpers/PageTitle';
import { StateWrapper } from 'docs/helpers/StateWrapper';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { FormComponentDemo } from '../../docs/helpers/FormComponentDemo';
import { Textbox, NotifiedTextbox, StyledTextbox } from '../index';
import { textbox as variants, sizes } from './helpers/variants';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageTitle src="components/Form/Textbox" title="Textbox" mdxType="PageTitle" />
    <h2 {...{
      "id": "basic-textbox"
    }}>{`Basic textbox`}</h2>
    <Playground __position={1} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm key={variant} py={3}>\n        <FormComponentDemo\n          unwrapped\n          initialValue=\"Hello world\"\n          property=\"value\"\n          type=\"textbox\"\n        >\n          <Textbox\n            onChange={(event, value) => console.log(value)}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ArrowLeftIcon,
      ArrowRightIcon,
      SearchIcon,
      SpinnerIcon,
      EmailIcon,
      Button,
      Flex,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      FormComponentDemo,
      Textbox,
      NotifiedTextbox,
      StyledTextbox,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm key={variant} py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped initialValue="Hello world" property="value" type="textbox" mdxType="FormComponentDemo">
            <Textbox onChange={(event, value) => console.log(value)} variant={variant} mdxType="Textbox" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "labeled-textbox"
    }}>{`Labeled textbox`}</h2>
    <Playground __position={2} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm key={variant} py={3}>\n        <FormComponentDemo\n          unwrapped\n          initialValue=\"Hello world\"\n          property=\"value\"\n          type=\"textbox\"\n        >\n          <Textbox\n            label=\"Super fantastic label\"\n            onChange={(event, value) => console.log(value)}\n            onSubmit={(event, value) => console.log(\'submit\', value)}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ArrowLeftIcon,
      ArrowRightIcon,
      SearchIcon,
      SpinnerIcon,
      EmailIcon,
      Button,
      Flex,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      FormComponentDemo,
      Textbox,
      NotifiedTextbox,
      StyledTextbox,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm key={variant} py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped initialValue="Hello world" property="value" type="textbox" mdxType="FormComponentDemo">
            <Textbox label="Super fantastic label" onChange={(event, value) => console.log(value)} onSubmit={(event, value) => console.log('submit', value)} variant={variant} mdxType="Textbox" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "transitional-labeled-textbox"
    }}>{`Transitional labeled textbox`}</h2>
    <Playground __position={3} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm key={variant} py={3}>\n        <FormComponentDemo unwrapped property=\"value\" type=\"textbox\">\n          <Textbox\n            transitional\n            label=\"Super fantastic label\"\n            onChange={(event, value) => console.log(value)}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ArrowLeftIcon,
      ArrowRightIcon,
      SearchIcon,
      SpinnerIcon,
      EmailIcon,
      Button,
      Flex,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      FormComponentDemo,
      Textbox,
      NotifiedTextbox,
      StyledTextbox,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm key={variant} py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped property="value" type="textbox" mdxType="FormComponentDemo">
            <Textbox transitional label="Super fantastic label" onChange={(event, value) => console.log(value)} variant={variant} mdxType="Textbox" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "transitional-clearable-labeled-textbox"
    }}>{`Transitional, clearable labeled textbox`}</h2>
    <Playground __position={4} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm key={variant} py={3}>\n        <FormComponentDemo unwrapped property=\"value\" type=\"textbox\">\n          <Textbox\n            clearable\n            transitional\n            label=\"Super fantastic label\"\n            onChange={(event, value) => console.log(value)}\n            onClear={() => console.log(\'clear\')}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ArrowLeftIcon,
      ArrowRightIcon,
      SearchIcon,
      SpinnerIcon,
      EmailIcon,
      Button,
      Flex,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      FormComponentDemo,
      Textbox,
      NotifiedTextbox,
      StyledTextbox,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm key={variant} py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped property="value" type="textbox" mdxType="FormComponentDemo">
            <Textbox clearable transitional label="Super fantastic label" onChange={(event, value) => console.log(value)} onClear={() => console.log('clear')} variant={variant} mdxType="Textbox" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "transitional-labeled-textbox-with-icons"
    }}>{`Transitional labeled textbox with icons`}</h2>
    <Playground __position={5} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm key={variant} py={3}>\n        <FormComponentDemo unwrapped property=\"value\" type=\"textbox\">\n          <Textbox\n            transitional\n            iconAfter={<SpinnerIcon size={14} />}\n            iconBefore={SearchIcon}\n            label=\"Super fantastic label\"\n            onChange={(event, value) => console.log(value)}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ArrowLeftIcon,
      ArrowRightIcon,
      SearchIcon,
      SpinnerIcon,
      EmailIcon,
      Button,
      Flex,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      FormComponentDemo,
      Textbox,
      NotifiedTextbox,
      StyledTextbox,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm key={variant} py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped property="value" type="textbox" mdxType="FormComponentDemo">
            <Textbox transitional iconAfter={<SpinnerIcon size={14} mdxType="SpinnerIcon" />} iconBefore={SearchIcon} label="Super fantastic label" onChange={(event, value) => console.log(value)} variant={variant} mdxType="Textbox" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "labeled-textbox-with-placeholder"
    }}>{`Labeled textbox with placeholder`}</h2>
    <Playground __position={6} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm key={variant} py={3}>\n        <FormComponentDemo unwrapped property=\"value\" type=\"textbox\">\n          <Textbox\n            label=\"Super fantastic label\"\n            onChange={(event, value) => console.log(value)}\n            onSubmit={(event, value) => console.log(\'submit\', value)}\n            placeholder=\"Hello world\"\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ArrowLeftIcon,
      ArrowRightIcon,
      SearchIcon,
      SpinnerIcon,
      EmailIcon,
      Button,
      Flex,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      FormComponentDemo,
      Textbox,
      NotifiedTextbox,
      StyledTextbox,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm key={variant} py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped property="value" type="textbox" mdxType="FormComponentDemo">
            <Textbox label="Super fantastic label" onChange={(event, value) => console.log(value)} onSubmit={(event, value) => console.log('submit', value)} placeholder="Hello world" variant={variant} mdxType="Textbox" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "labeled-textbox-with-html-placeholder"
    }}>{`Labeled textbox with HTML placeholder`}</h2>
    <Playground __position={7} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm key={variant} py={3}>\n        <FormComponentDemo unwrapped property=\"value\" type=\"textbox\">\n          <Textbox\n            label=\"Super fantastic label\"\n            onChange={(event, value) => console.log(value)}\n            onSubmit={(event, value) => console.log(\'submit\', value)}\n            placeholder={<i>Hello world</i>}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ArrowLeftIcon,
      ArrowRightIcon,
      SearchIcon,
      SpinnerIcon,
      EmailIcon,
      Button,
      Flex,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      FormComponentDemo,
      Textbox,
      NotifiedTextbox,
      StyledTextbox,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm key={variant} py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped property="value" type="textbox" mdxType="FormComponentDemo">
            <Textbox label="Super fantastic label" onChange={(event, value) => console.log(value)} onSubmit={(event, value) => console.log('submit', value)} placeholder={<i>Hello world</i>} variant={variant} mdxType="Textbox" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "transitional-labeled-textbox-with-html-value"
    }}>{`Transitional labeled textbox with HTML value`}</h2>
    <Playground __position={8} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <StateWrapper key={variant}>\n        {({ state: value, setState: setValue }) => (\n          <Rhythm grouped py={3} style={{ maxWidth: 400 }}>\n            <Textbox\n              alwaysUseFormatting\n              clearable\n              transitional\n              formattedValue={value ? <i>{value}</i> : undefined}\n              label=\"Super fantastic label\"\n              onChange={(event, value) => setValue(value)}\n              onClear={() => console.log(\'clear\')}\n              value={value}\n              variant={variant}\n            />\n          </Rhythm>\n        )}\n      </StateWrapper>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ArrowLeftIcon,
      ArrowRightIcon,
      SearchIcon,
      SpinnerIcon,
      EmailIcon,
      Button,
      Flex,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      FormComponentDemo,
      Textbox,
      NotifiedTextbox,
      StyledTextbox,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <StateWrapper key={variant} mdxType="StateWrapper">
          {({
            state: value,
            setState: setValue
          }) => <Rhythm grouped py={3} style={{
            maxWidth: 400
          }} mdxType="Rhythm">
              <Textbox alwaysUseFormatting clearable transitional formattedValue={value ? <i>{value}</i> : undefined} label="Super fantastic label" onChange={(event, value) => setValue(value)} onClear={() => console.log('clear')} value={value} variant={variant} mdxType="Textbox" />
            </Rhythm>}
        </StateWrapper>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "clearable-search-textbox"
    }}>{`Clearable search textbox`}</h2>
    <Playground __position={9} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm key={variant} py={3}>\n        <FormComponentDemo\n          unwrapped\n          initialValue=\"Hello world\"\n          property=\"value\"\n          type=\"textbox\"\n        >\n          <Textbox\n            clearable\n            transitional\n            iconBefore={<SearchIcon />}\n            label=\"Super fantastic label\"\n            onChange={(event, value) => console.log(value)}\n            onClear={() => console.log(\'clear\')}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ArrowLeftIcon,
      ArrowRightIcon,
      SearchIcon,
      SpinnerIcon,
      EmailIcon,
      Button,
      Flex,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      FormComponentDemo,
      Textbox,
      NotifiedTextbox,
      StyledTextbox,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm key={variant} py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped initialValue="Hello world" property="value" type="textbox" mdxType="FormComponentDemo">
            <Textbox clearable transitional iconBefore={<SearchIcon mdxType="SearchIcon" />} label="Super fantastic label" onChange={(event, value) => console.log(value)} onClear={() => console.log('clear')} variant={variant} mdxType="Textbox" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "read-only-textbox"
    }}>{`Read only textbox`}</h2>
    <Playground __position={10} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm key={variant} py={3}>\n        <FormComponentDemo\n          unwrapped\n          initialValue=\"Hello world\"\n          property=\"value\"\n          type=\"textbox\"\n        >\n          <Textbox\n            readOnly\n            label=\"Super fantastic label\"\n            onChange={(event, value) => console.log(value)}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ArrowLeftIcon,
      ArrowRightIcon,
      SearchIcon,
      SpinnerIcon,
      EmailIcon,
      Button,
      Flex,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      FormComponentDemo,
      Textbox,
      NotifiedTextbox,
      StyledTextbox,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm key={variant} py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped initialValue="Hello world" property="value" type="textbox" mdxType="FormComponentDemo">
            <Textbox readOnly label="Super fantastic label" onChange={(event, value) => console.log(value)} variant={variant} mdxType="Textbox" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "disabled-textbox"
    }}>{`Disabled textbox`}</h2>
    <Playground __position={11} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm key={variant} py={3}>\n        <FormComponentDemo\n          unwrapped\n          initialValue=\"Hello world\"\n          property=\"value\"\n          type=\"textbox\"\n        >\n          <Textbox\n            disabled\n            label=\"Super fantastic label\"\n            onChange={(event, value) => console.log(value)}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ArrowLeftIcon,
      ArrowRightIcon,
      SearchIcon,
      SpinnerIcon,
      EmailIcon,
      Button,
      Flex,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      FormComponentDemo,
      Textbox,
      NotifiedTextbox,
      StyledTextbox,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm key={variant} py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped initialValue="Hello world" property="value" type="textbox" mdxType="FormComponentDemo">
            <Textbox disabled label="Super fantastic label" onChange={(event, value) => console.log(value)} variant={variant} mdxType="Textbox" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "textbox-with-focusblur-callbacks"
    }}>{`Textbox with focus/blur callbacks`}</h2>
    <Playground __position={12} __code={'<StateWrapper initialState={false}>\n  {({ state: isFocused, setState: setFocused }) => (\n    <FormComponentDemo property=\"value\" type=\"textbox\">\n      <Textbox\n        label={isFocused ? \'Focused label\' : \'Blurred label\'}\n        onBlur={() => setFocused(false)}\n        onChange={(event, value) => console.log(value)}\n        onFocus={() => setFocused(true)}\n        placeholder=\"Hello world\"\n      />\n    </FormComponentDemo>\n  )}\n</StateWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ArrowLeftIcon,
      ArrowRightIcon,
      SearchIcon,
      SpinnerIcon,
      EmailIcon,
      Button,
      Flex,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      FormComponentDemo,
      Textbox,
      NotifiedTextbox,
      StyledTextbox,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StateWrapper initialState={false} mdxType="StateWrapper">
    {({
          state: isFocused,
          setState: setFocused
        }) => <FormComponentDemo property="value" type="textbox" mdxType="FormComponentDemo">
        <Textbox label={isFocused ? 'Focused label' : 'Blurred label'} onBlur={() => setFocused(false)} onChange={(event, value) => console.log(value)} onFocus={() => setFocused(true)} placeholder="Hello world" mdxType="Textbox" />
      </FormComponentDemo>}
  </StateWrapper>
    </Playground>
    <h2 {...{
      "id": "danger-textbox"
    }}>{`Danger textbox`}</h2>
    <Playground __position={13} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm key={variant} py={3}>\n        <FormComponentDemo\n          unwrapped\n          initialValue=\"Hello world\"\n          property=\"value\"\n          type=\"textbox\"\n        >\n          <Textbox\n            transitional\n            label=\"Super fantastic label\"\n            onChange={(event, value) => console.log(value)}\n            validity=\"danger\"\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ArrowLeftIcon,
      ArrowRightIcon,
      SearchIcon,
      SpinnerIcon,
      EmailIcon,
      Button,
      Flex,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      FormComponentDemo,
      Textbox,
      NotifiedTextbox,
      StyledTextbox,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm key={variant} py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped initialValue="Hello world" property="value" type="textbox" mdxType="FormComponentDemo">
            <Textbox transitional label="Super fantastic label" onChange={(event, value) => console.log(value)} validity="danger" variant={variant} mdxType="Textbox" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "warning-textbox"
    }}>{`Warning textbox`}</h2>
    <Playground __position={14} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm key={variant} py={3}>\n        <FormComponentDemo\n          unwrapped\n          initialValue=\"Hello world\"\n          property=\"value\"\n          type=\"textbox\"\n        >\n          <Textbox\n            transitional\n            label=\"Super fantastic label\"\n            onChange={(event, value) => console.log(value)}\n            validity=\"warning\"\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ArrowLeftIcon,
      ArrowRightIcon,
      SearchIcon,
      SpinnerIcon,
      EmailIcon,
      Button,
      Flex,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      FormComponentDemo,
      Textbox,
      NotifiedTextbox,
      StyledTextbox,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm key={variant} py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped initialValue="Hello world" property="value" type="textbox" mdxType="FormComponentDemo">
            <Textbox transitional label="Super fantastic label" onChange={(event, value) => console.log(value)} validity="warning" variant={variant} mdxType="Textbox" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "success-textbox"
    }}>{`Success textbox`}</h2>
    <Playground __position={15} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm key={variant} py={3}>\n        <FormComponentDemo\n          unwrapped\n          initialValue=\"Hello world\"\n          property=\"value\"\n          type=\"textbox\"\n        >\n          <Textbox\n            transitional\n            label=\"Super fantastic label\"\n            onChange={(event, value) => console.log(value)}\n            validity=\"success\"\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ArrowLeftIcon,
      ArrowRightIcon,
      SearchIcon,
      SpinnerIcon,
      EmailIcon,
      Button,
      Flex,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      FormComponentDemo,
      Textbox,
      NotifiedTextbox,
      StyledTextbox,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm key={variant} py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped initialValue="Hello world" property="value" type="textbox" mdxType="FormComponentDemo">
            <Textbox transitional label="Super fantastic label" onChange={(event, value) => console.log(value)} validity="success" variant={variant} mdxType="Textbox" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "contrast-textbox"
    }}>{`Contrast textbox`}</h2>
    <Playground __position={16} __code={'<ThemeWrapper contrast>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm key={variant} py={3}>\n        <FormComponentDemo contrast unwrapped property=\"value\" type=\"textbox\">\n          <Textbox\n            clearable\n            contrast\n            transitional\n            iconBefore={<SearchIcon />}\n            label=\"Super fantastic label\"\n            onChange={(event, value) => console.log(value)}\n            onClear={() => console.log(\'clear\')}\n            placeholder=\"Hello world\"\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ArrowLeftIcon,
      ArrowRightIcon,
      SearchIcon,
      SpinnerIcon,
      EmailIcon,
      Button,
      Flex,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      FormComponentDemo,
      Textbox,
      NotifiedTextbox,
      StyledTextbox,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper contrast mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm key={variant} py={3} mdxType="Rhythm">
          <FormComponentDemo contrast unwrapped property="value" type="textbox" mdxType="FormComponentDemo">
            <Textbox clearable contrast transitional iconBefore={<SearchIcon mdxType="SearchIcon" />} label="Super fantastic label" onChange={(event, value) => console.log(value)} onClear={() => console.log('clear')} placeholder="Hello world" variant={variant} mdxType="Textbox" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "styled-textbox"
    }}>{`Styled textbox`}</h2>
    <Playground __position={17} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm key={variant} py={3}>\n        <FormComponentDemo unwrapped property=\"value\" type=\"textbox\">\n          <StyledTextbox\n            clearable\n            transitional\n            label=\"Super fantastic label\"\n            onChange={(event, value) => console.log(value)}\n            placeholder=\"Hello world\"\n            textboxIconColor={\'#9D69D5\'}\n            textboxIconHoveredColor={\'#642da0\'}\n            textboxInputContainerBackgroundColor={\'transparent\'}\n            textboxInputContainerBorderColor={\'#9D69D5\'}\n            textboxInputContainerFocusedBorderColor={\'#57278C\'}\n            textboxInputContainerHoveredBorderColor={\'#642da0\'}\n            textboxInputTextColor={\'#642da0\'}\n            textboxLabelTextColor={\'#B995E1\'}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ArrowLeftIcon,
      ArrowRightIcon,
      SearchIcon,
      SpinnerIcon,
      EmailIcon,
      Button,
      Flex,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      FormComponentDemo,
      Textbox,
      NotifiedTextbox,
      StyledTextbox,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm key={variant} py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped property="value" type="textbox" mdxType="FormComponentDemo">
            <StyledTextbox clearable transitional label="Super fantastic label" onChange={(event, value) => console.log(value)} placeholder="Hello world" textboxIconColor={'#9D69D5'} textboxIconHoveredColor={'#642da0'} textboxInputContainerBackgroundColor={'transparent'} textboxInputContainerBorderColor={'#9D69D5'} textboxInputContainerFocusedBorderColor={'#57278C'} textboxInputContainerHoveredBorderColor={'#642da0'} textboxInputTextColor={'#642da0'} textboxLabelTextColor={'#B995E1'} variant={variant} mdxType="StyledTextbox" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "auto-complete"
    }}>{`Auto complete`}</h2>
    <Playground __position={18} __code={'<ThemeWrapper>\n  <form autoComplete=\"on\" name=\"autoComplete\">\n    <Looper\n      list={variants}\n      render={variant => (\n        <Rhythm grouped key={variant} py={3}>\n          <FormComponentDemo unwrapped property=\"value\" type=\"textbox\">\n            <Textbox\n              clearable\n              transitional\n              autoComplete=\"email\"\n              iconBefore={EmailIcon}\n              label=\"Email\"\n              name={`email-${variant}`}\n              onChange={(event, value) => console.log(value)}\n              type=\"email\"\n              variant={variant}\n            />\n          </FormComponentDemo>\n        </Rhythm>\n      )}\n    />\n    <Rhythm ml={3} mt={4} pr={3} style={{ maxWidth: 400 }}>\n      <Flex justifyContent=\"flex-end\">\n        <Button color=\"primary\" shape=\"brick\" type=\"submit\" weight=\"shaded\">\n          Submit\n        </Button>\n      </Flex>\n    </Rhythm>\n  </form>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ArrowLeftIcon,
      ArrowRightIcon,
      SearchIcon,
      SpinnerIcon,
      EmailIcon,
      Button,
      Flex,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      FormComponentDemo,
      Textbox,
      NotifiedTextbox,
      StyledTextbox,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <form autoComplete="on" name="autoComplete">
      <Looper list={variants} render={variant => <Rhythm grouped key={variant} py={3} mdxType="Rhythm">
            <FormComponentDemo unwrapped property="value" type="textbox" mdxType="FormComponentDemo">
              <Textbox clearable transitional autoComplete="email" iconBefore={EmailIcon} label="Email" name={`email-${variant}`} onChange={(event, value) => console.log(value)} type="email" variant={variant} mdxType="Textbox" />
            </FormComponentDemo>
          </Rhythm>} mdxType="Looper" />
      <Rhythm ml={3} mt={4} pr={3} style={{
            maxWidth: 400
          }} mdxType="Rhythm">
        <Flex justifyContent="flex-end" mdxType="Flex">
          <Button color="primary" shape="brick" type="submit" weight="shaded" mdxType="Button">
            Submit
          </Button>
        </Flex>
      </Rhythm>
    </form>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "notifications"
    }}>{`Notifications`}</h2>
    <Playground __position={19} __code={'<ThemeWrapper>\n  <Looper\n    list={[\'danger\', \'warning\', \'success\', \'primary\', \'neutral\']}\n    render={level => (\n      <Looper\n        list={variants}\n        render={variant => (\n          <Rhythm key={`${variant}-${level}`} py={3}>\n            <FormComponentDemo\n              unwrapped\n              initialValue=\"Hello world\"\n              property=\"value\"\n              type=\"textbox\"\n            >\n              <NotifiedTextbox\n                clearable\n                transitional\n                label=\"Super fantastic label\"\n                level={level}\n                notification={`This is an example ${level} notification.`}\n                onChange={(event, value) => console.log(value)}\n                onClear={() => console.log(\'clear\')}\n                variant={variant}\n              />\n            </FormComponentDemo>\n          </Rhythm>\n        )}\n      />\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ArrowLeftIcon,
      ArrowRightIcon,
      SearchIcon,
      SpinnerIcon,
      EmailIcon,
      Button,
      Flex,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      FormComponentDemo,
      Textbox,
      NotifiedTextbox,
      StyledTextbox,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={['danger', 'warning', 'success', 'primary', 'neutral']} render={level => <Looper list={variants} render={variant => <Rhythm key={`${variant}-${level}`} py={3} mdxType="Rhythm">
              <FormComponentDemo unwrapped initialValue="Hello world" property="value" type="textbox" mdxType="FormComponentDemo">
                <NotifiedTextbox clearable transitional label="Super fantastic label" level={level} notification={`This is an example ${level} notification.`} onChange={(event, value) => console.log(value)} onClear={() => console.log('clear')} variant={variant} mdxType="NotifiedTextbox" />
              </FormComponentDemo>
            </Rhythm>} mdxType="Looper" />} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "sizes"
    }}>{`Sizes`}</h2>
    <Playground __position={20} __code={'<ThemeWrapper>\n  <Looper\n    list={sizes}\n    render={size => (\n      <Rhythm grouped key={size} my={3}>\n        <Looper\n          list={variants}\n          render={variant => (\n            <Rhythm key={`${size}-${variant}`} py={3}>\n              <FormComponentDemo unwrapped property=\"value\" type=\"textbox\">\n                <Textbox\n                  transitional\n                  iconAfter={ArrowRightIcon}\n                  iconBefore={ArrowLeftIcon}\n                  label={`Size ${size}`}\n                  onChange={(event, value) => console.log(value)}\n                  size={size}\n                  variant={variant}\n                />\n              </FormComponentDemo>\n            </Rhythm>\n          )}\n        />\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ArrowLeftIcon,
      ArrowRightIcon,
      SearchIcon,
      SpinnerIcon,
      EmailIcon,
      Button,
      Flex,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      FormComponentDemo,
      Textbox,
      NotifiedTextbox,
      StyledTextbox,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={sizes} render={size => <Rhythm grouped key={size} my={3} mdxType="Rhythm">
          <Looper list={variants} render={variant => <Rhythm key={`${size}-${variant}`} py={3} mdxType="Rhythm">
                <FormComponentDemo unwrapped property="value" type="textbox" mdxType="FormComponentDemo">
                  <Textbox transitional iconAfter={ArrowRightIcon} iconBefore={ArrowLeftIcon} label={`Size ${size}`} onChange={(event, value) => console.log(value)} size={size} variant={variant} mdxType="Textbox" />
                </FormComponentDemo>
              </Rhythm>} mdxType="Looper" />
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`[`}{`props`}{`]`}</h2>
    <h3 {...{
      "id": "textbox"
    }}>{`Textbox`}</h3>
    <Props of={Textbox} mdxType="Props" />
    <h3 {...{
      "id": "styledtextbox"
    }}>{`StyledTextbox`}</h3>
    <Props of={StyledTextbox} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      